var editor;

function initializeRichText() {
// console.log('initializing rich text');
editor = new tui.Editor({
  el: document.querySelector('#av_chat_input_richtext'),
  previewStyle: 'vertical',
  hideModeSwitch: true,
  height: '100px',
  initialEditType: 'wysiwyg',
  //exts: ['colorSyntax'],
  usageStatistics: false
});

}



function formatOutgoingMessage() {
  // var messageFormat = document.querySelector('#av_chat_msg_format').value;
  var messageFormat = "PLAINTEXT";
  
  var body = "";
  if('HTML'===messageFormat) {
    body = editor.getHtml();
  } else {
    body = editor.getMarkdown();
  }

  // console.log('formatOutgoingMessage :: result body = ' + body);

  return body;
}

function convertToHTML(body) {
  return editor.convertor.toHTML(body);
}

function toggleTUIToolbarFromJS() {
	var selectValue = document.getElementById('av_chat_msg_format');
	var formatSelected = selectValue.options[selectValue.selectedIndex].value;
	// console.log("messageFormat selected : " + formatSelected);
    if(formatSelected === 'PLAINTEXT'){
    	document.getElementsByClassName("tui-editor-defaultUI-toolbar")[0].hidden = true;
    }
    else{
    	document.getElementsByClassName("tui-editor-defaultUI-toolbar")[0].hidden = false;
    }
}

function clearMessage() {
  editor.setMarkdown("");
}
